import { NextPage } from 'next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { AppDispatch, AppState } from '../../api/types/auth'
import { signIn } from '../../api/ducks/auth'
import {
    AlertsInterface,
    LoginPageProps,
    UserLogin
} from '../../libs/interfaces'
import { Auth } from 'aws-amplify'
import { useRouter } from 'next/router'
import { AUTH_CONSTANT } from '../../libs/utils/constants'

import {
    JotaMaterialClose,
    Tag,
    tokens
} from '@JOTAJornalismo/jota-design-system'
import { validateEmail } from '../../libs/utils/validation'
import Link from 'next/link'
import { LogoJOTA, DialogDS, InputDS, ButtonDS } from '../../libs/components'
import { LoadingLogin } from '../../libs/components/loading/login.loading'
import {
    LoginContainerRoot,
    LoginContainer,
    Logo,
    Header04,
    Paragraph,
    FormContainer,
    BreadcrumbContainer,
    LoginHeader,
    NotProYetContainer,
    TitleJotaPro,
    ParagraphContent,
    TextJotaPro,
    MobileText,
    UnderButtonText
} from '../../static/styles/login.styles'
import { INSIDE_JU } from '@App/libs/utils/constants/auth'
import Head from 'next/head'
import {
    PRO_PODER,
    PRO_PODER_LOGIN_TEXT_1,
    PRO_PODER_LOGIN_TEXT_2,
    PRO_TRABALHISTA,
    PRO_TRABALHISTA_LOGIN_TEXT_1,
    PRO_TRABALHISTA_LOGIN_TEXT_2,
    PRO_TRIBUTOS,
    PRO_TRIBUTOS_LOGIN_TEXT_1,
    PRO_TRIBUTOS_LOGIN_TEXT_2
} from '@App/libs/utils/constants/global'
import { addDatalayer } from '@App/libs/utils/geral'
import BreadcrumbComponentJU, {
    BreadHome
} from '@App/libs/components/ds/Breadcrumb'
import { VerticalNameActive } from '@App/libs/interfaces/PRO.interfaces'

const { colors } = tokens

declare const window: Window & { dataLayer: Record<string, unknown>[] }

const LoginPage: NextPage<LoginPageProps> = ({
    signInState,
    error,
    user,
    authState
}) => {
    const loginRef = useRef<UserLogin['email']>(null)
    const passwordRef = useRef<UserLogin['password']>(null)
    const [loginInput, setLoginInput] = useState<string>('')
    const [passwordInput, setPasswordInput] = useState<string>('')
    const [loginClientRender, setLoginClientRender] = useState(false)

    const [alerts, setAlerts] = useState<AlertsInterface>({
        btnDisabled: true,
        error: false,
        loading: 'first',
        emailStatus: {
            status: 'normal',
            message: ''
        },
        passwordStatus: {
            status: 'normal',
            message: ''
        },
        preserve: false
    })
    const router = useRouter()

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(() => {
            const cameFromJu = localStorage.getItem(INSIDE_JU)
            if (cameFromJu) {
                localStorage.removeItem(INSIDE_JU)
                window.history.back()
            } else {
                router.push('/')
            }
        })
    }, [router])

    /**
     * Make Error
     * @param {boolean} status
     */
    const makeError = useCallback(
        (status: boolean) => {
            if (status) {
                setAlerts((pref) => ({
                    ...pref,
                    loading: false,
                    btnDisabled: true,
                    error: error ?? false
                }))
            } else {
                setAlerts((pref) => ({ ...pref, error: false }))
            }
        },
        [error]
    )

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(() => {
            const cameFromJu = localStorage.getItem(INSIDE_JU)
            if (cameFromJu) {
                localStorage.removeItem(INSIDE_JU)
                window.history.back()
            } else {
                router.push('/')
            }
        })

        if (error !== 'logout' && error) {
            makeError(true)
        } else {
            makeError(false)
        }
    }, [error, router, authState, user, makeError])

    useEffect(() => {
        setLoginClientRender(true)
    }, [])

    /**
     * Make Login
     */
    async function makeLogin() {
        const email = loginRef.current?.value
        const password = passwordRef.current?.value

        if (email && password) {
            setAlerts({ ...alerts, loading: true })
            const response = await signInState(email, password)

            if (response && response === 'NEW_PASSWORD_REQUIRED') {
                router.push(
                    `/redefinir-senha?username=${email}&pass=${password}&firstLogin=1`
                )
            }
        }
    }

    /**
     * Check Fields
     */
    function checkFields(): void {
        const email = loginRef.current?.value
        const password = passwordRef.current?.value

        setAlerts({
            ...alerts,
            error: false,
            btnDisabled: true
        })

        if (!email || email === '') return
        if (!password || password === '') return

        setLoginInput(email)
        setPasswordInput(password)

        const isEmailValid = validateEmail(email)
        const isPasswordValid = !(!password || password === '')

        setAlerts({
            ...alerts,
            error: !isEmailValid,
            btnDisabled: !isPasswordValid || !isEmailValid,
            emailStatus: {
                ...alerts.emailStatus,
                status: isEmailValid ? 'normal' : 'error',
                message: isEmailValid
                    ? ''
                    : AUTH_CONSTANT.LOGIN_FIELDS_MESSAGE.emailWrong
            }
        })
    }

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault()

                makeLogin()
            }
        }

        document.addEventListener('keydown', keyDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openedContentVertical = router.asPath.split('=')[1] || 'general'

    const openLPByVertical = (vertical: string) => {
        const lpMap: Record<string, string | undefined> = {
            PODER: process.env.NEXT_PUBLIC_LP_PODER,
            TRIBUTOS: process.env.NEXT_PUBLIC_LP_TRIBUTOS,
            SAUDE: process.env.NEXT_PUBLIC_LP_SAUDE,
            TRABALHISTA: process.env.NEXT_PUBLIC_LP_TRABALHISTA
        }

        return lpMap[vertical]
    }

    const getCopyByVertical = (vertical: VerticalNameActive) => {
        const copys = {
            PODER: [PRO_PODER_LOGIN_TEXT_1, PRO_PODER_LOGIN_TEXT_2],
            TRIBUTOS: [PRO_TRIBUTOS_LOGIN_TEXT_1, PRO_TRIBUTOS_LOGIN_TEXT_2],
            TRABALHISTA: [
                PRO_TRABALHISTA_LOGIN_TEXT_1,
                PRO_TRABALHISTA_LOGIN_TEXT_2
            ]
        }

        return (
            <TextJotaPro className='login_text_container'>
                <h5>Conheça a melhor plataforma do mercado.</h5>
                <p>
                    {copys[vertical as keyof typeof copys][0]}
                    <br />
                    <br />
                    {copys[vertical as keyof typeof copys][1]}
                </p>
            </TextJotaPro>
        )
    }

    const getCopyAccordingToParams = (param: string) => {
        const allowedParams = new Set([
            PRO_TRIBUTOS,
            PRO_PODER,
            PRO_TRABALHISTA
        ])

        if (allowedParams.has(param)) {
            return (
                loginClientRender && (
                    <>
                        {getCopyByVertical(
                            param.toUpperCase() as VerticalNameActive
                        )}

                        <div>
                            <ButtonDS
                                id='conheça-agora-login'
                                label='Conheça agora'
                                onClick={() => {
                                    addDatalayer({
                                        event: 'click_login_conheça_pro',
                                        openedLp: `${openedContentVertical} _lp`
                                    })

                                    window.open(
                                        openLPByVertical(param.toUpperCase()),
                                        '_blank'
                                    )
                                }}
                                size='large'
                                type='filled'
                                color='white'
                            />
                        </div>
                        <UnderButtonText>
                            Procurando soluções para pessoas físicas? <br />
                            <strong>
                                <Link
                                    href={`${process.env.NEXT_PUBLIC_JOTAINFO}/newsletter-jota`}
                                    target='_blank'
                                >
                                    Aproveite nossos conteúdos grátis!
                                </Link>
                            </strong>
                        </UnderButtonText>
                    </>
                )
            )
        } else {
            return (
                loginClientRender && (
                    <>
                        <TextJotaPro className='login_text_container'>
                            <h5>Tenha acesso a conteúdos exclusivos.</h5>O{' '}
                            <Link
                                href={`${process.env.NEXT_PUBLIC_LP_GERAL_PRO}`}
                                target='_blank'
                            >
                                <strong id='jota-pro-login'>JOTA PRO</strong>
                            </Link>{' '}
                            é um serviço corporativo de inteligência jurídica,
                            política e monitoramento do cenário institucional do
                            país.
                            <p>
                                Experimente grátis e entenda por que o{' '}
                                <strong>JOTA</strong> é referência para quem
                                precisa compreender como os três Poderes afetam
                                seu negócio.
                            </p>
                        </TextJotaPro>

                        <div>
                            <ButtonDS
                                id='conheça-agora-login'
                                label='Conheça agora'
                                onClick={() => {
                                    addDatalayer({
                                        event: 'click_login_conheça_pro',
                                        openedLp: `${openedContentVertical} _lp`
                                    })
                                    window.open(
                                        process.env.NEXT_PUBLIC_LP_GERAL_PRO,
                                        '_blank'
                                    )
                                }}
                                size='large'
                                type='filled'
                                color='white'
                            />
                        </div>
                    </>
                )
            )
        }
    }

    return (
        <LoginContainerRoot className='w-100'>
            <Head>
                <title> Login - JOTA</title>
            </Head>
            <LoginContainer>
                <BreadcrumbContainer>
                    <BreadcrumbComponentJU
                        items={[
                            BreadHome,
                            {
                                label: 'Login',
                                slug: 'login',
                                clickable: false
                            }
                        ]}
                    />

                    <JotaMaterialClose
                        fill={colors.gray.jotaGray}
                        onClick={() => {
                            window.open('/', '_self')
                        }}
                    />
                </BreadcrumbContainer>
                <Logo>
                    <LogoJOTA />
                </Logo>
                <Header04>Login</Header04>
                <Paragraph>
                    Entre para acessar conteúdos exclusivos<strong>PRO.</strong>
                </Paragraph>

                <MobileText>
                    Ainda não é PRO?{' '}
                    <Link href='/pro'>
                        <strong>Conheça nossos planos!</strong>
                    </Link>
                </MobileText>

                {alerts.loading === 'first' || !alerts.loading ? (
                    loginClientRender && (
                        <FormContainer data-test='login-form'>
                            {alerts.error === true ||
                                (typeof alerts.error == 'string' &&
                                    alerts.error.includes(
                                        'Incorrect username or password.'
                                    ) && (
                                        <div>
                                            <DialogDS
                                                message='E-mail ou senha incorretos. Tente novamente.'
                                                type='error'
                                            />
                                        </div>
                                    ))}

                            <InputDS
                                status={
                                    (typeof alerts.error == 'string' &&
                                        alerts.error != 'NOT_LOGGED' &&
                                        loginRef?.current &&
                                        'error') ||
                                    alerts.emailStatus.status
                                }
                                type='text'
                                label='E-mail'
                                placeholder={
                                    AUTH_CONSTANT.LOGIN_FIELDS_MESSAGE
                                        .emailPlaceholder
                                }
                                alternativeText={alerts.emailStatus.message}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    e.preventDefault()
                                    checkFields()
                                }}
                                value={loginInput}
                                useRefInput={loginRef}
                            />

                            <InputDS
                                status={
                                    (typeof alerts.error == 'string' &&
                                        alerts.error != 'NOT_LOGGED' &&
                                        passwordRef?.current &&
                                        'error') ||
                                    alerts?.passwordStatus?.status
                                }
                                type='password'
                                label='Senha'
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    e.preventDefault()
                                    checkFields()
                                }}
                                value={passwordInput}
                                useRefInput={passwordRef}
                            />

                            <Link href='/esqueci-a-senha'>
                                {' '}
                                <strong> Esqueceu a senha?</strong>
                            </Link>

                            <ButtonDS
                                disabled={alerts.btnDisabled}
                                label='Entrar'
                                onClick={() => void makeLogin()}
                                size='medium'
                                type='filled'
                                width='100%'
                            />
                        </FormContainer>
                    )
                ) : (
                    <LoadingLogin />
                )}
            </LoginContainer>

            <LoginHeader>
                <JotaMaterialClose
                    fill={colors.gray.jotaGray}
                    type='large'
                    onClick={() => {
                        window.open('/', '_self')
                    }}
                />
            </LoginHeader>

            <NotProYetContainer>
                <ParagraphContent>
                    <Tag
                        label={'Exclusivo para empresas'}
                        size='medium'
                        type='outline'
                        color='jotaWhite'
                        disableHover
                    />
                    <TitleJotaPro>Ainda não é PRO?</TitleJotaPro>
                    {getCopyAccordingToParams(openedContentVertical)}
                </ParagraphContent>
            </NotProYetContainer>
        </LoginContainerRoot>
    )
}

const mapStateToProps = ({ auth }: AppState) => ({
    user: auth.user ?? null,
    authState: auth.authState,
    loading: auth.loading,
    error: auth.error
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    signInState: async (email: string, password: string) => {
        return await dispatch(signIn(email, password))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
